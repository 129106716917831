import React, { useState } from "react"
import emailjs from "emailjs-com"
import PageTitle from "../../Elements/PageTitle"
import {
  Section,
  GrayBorder,
  H3Title,
  OrangeSpan,
  P,
  DoubleGrid,
  M,
} from "../../../globalStyle"
import {
  Container,
  Col,
  TextInput,
  TextArea,
  Label,
  RadioLabel,
  Radio,
  Mark,
  TalkWrapper,
  Info,
  SubmitButton,
} from "./style"

import { Form, ErrorMessage, Field, Formik } from "formik"
import Recaptcha from "react-recaptcha"
import * as Yup from "yup"

/*  for file input //todo
import ButtonArrow from "../../UI/ButtonArrow"
*/

import { init } from "emailjs-com"
init("user_hE3aWlAf88ZkxZg9y7mH6")

const ContactForm = () => {
  const [verified, setVerified] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [succes, setSuccess] = useState<boolean>(false)
  const [fail, setFail] = useState<boolean>(false)
  /* file input //  todo
  const [fileName, setFileName] = useState<string>("No file chosen")

  const handleChangeFile = event => {
    setFileName(event.target.files[0].name)
  }
*/
  const recaptchaLoaded = () => {}
  const verifiedCallback = response => {
    if (response) {
      setVerified(true)
    }
  }

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    company: "",
    idea: "",
    suitsYouBest: "",
    productFunded: "",
    budget: "",
  }

  function sendEmail(object) {
    if (verified === true) {
      setSending(true)
      emailjs
        .send(
          "oakfusiongmail",
          "template_18slhgg",
          object,
          "user_hE3aWlAf88ZkxZg9y7mH6"
        )
        .then(
          result => {
            setSuccess(true)
            setSending(false)
          },
          error => {
            setFail(true)
            setSending(false)
          }
        )
    } else {
      alert("Please verify that you are a human by checking a checkbox! ")
    }
  }

  const phoneRegExp = /^((\\+[1-9 +]{1,4}[ \\-]*)|(\\([0-9 +]{2,3}\\)[ \\-]*)|([0-9 +]{2,4})[ \\-]*)*?[0-9 +]{3,4}?[ \\-]*[0-9 +]{3,4}?$/

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("This field is required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    company: Yup.string(),
    idea: Yup.string().required("This field is required"),
    suitsYouBest: Yup.string().required("This field is required"),
    productFunded: Yup.string().required("This field is required"),
    budget: Yup.string().required("This field is required"),
  })

  const TextError = props => {
    return (
      <span style={{ color: "red", transition: "0.3s" }}>{props.children}</span>
    )
  }

  return (
    <Section style={{ marginTop: "80px" }}>
      <PageTitle title="Contact" />
      <GrayBorder />
      <TalkWrapper>
        <M>
          <H3Title isDarkBlue={true}>
            Let's <OrangeSpan>talk</OrangeSpan>
          </H3Title>
          <P>
            Fill out the form or write to us at:{" "}
            <OrangeSpan>office@oakfusion.pl</OrangeSpan>
          </P>
        </M>
      </TalkWrapper>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          sendEmail(values)
        }}
      >
        <Container>
          <Form>
            <DoubleGrid>
              <M>
                <Col>
                  <Label htmlFor="name">Your Name</Label>
                  <TextInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder="your name..."
                  />
                  <ErrorMessage name="name" component={TextError} />
                  <Label htmlFor="email">Email Address</Label>
                  <TextInput
                    type="text"
                    name="email"
                    id="email"
                    placeholder="your email address..."
                  />
                  <ErrorMessage name="email" component={TextError} />
                  <Label htmlFor="phone">Phone Number</Label>
                  <Info>(optional)</Info>
                  <TextInput
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="your phone number..."
                  />
                  <ErrorMessage name="phone" component={TextError} />
                  <Label htmlFor="company">Company</Label>
                  <Info>(optional)</Info>
                  <TextInput
                    type="text"
                    name="company"
                    id="company"
                    placeholder="your company name..."
                  />
                  <ErrorMessage name="company" component={TextError} />
                  <Label htmlFor="idea">Got an Idea? Tell Us about it</Label>

                  <Field name="idea" as="textarea">
                    {({ field, form, meta }) => {
                      return (
                        <TextArea
                          value={field.value}
                          onChange={field.onChange}
                          as="textarea"
                          id="idea"
                          name="idea"
                          placeholder="your message..."
                        />
                      )
                    }}
                  </Field>

                  <ErrorMessage name="idea" component={TextError} />
                  <TextInput
                    type="hidden"
                    name="site"
                    id="site"
                    value="Oakfusion.pl"
                  />
                </Col>
              </M>
              <M>
                <Col>
                  <Label>Choose whichever suits you best:</Label>
                  <div>
                    <RadioLabel htmlFor="small">
                      <Radio
                        type="radio"
                        id="small"
                        name="suitsYouBest"
                        value="I'm a startup"
                        checked
                      />
                      I'm a startup
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="medium">
                      <Radio
                        type="radio"
                        id="medium"
                        name="suitsYouBest"
                        value="I'm a small or medium business"
                      />
                      I'm a small or medium business
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="big">
                      <Radio
                        type="radio"
                        id="big"
                        name="suitsYouBest"
                        value="I'm an enterprise company"
                      />
                      I'm an enterprise company
                      <Mark />
                    </RadioLabel>
                  </div>
                  <ErrorMessage name="suitsYouBest" component={TextError} />

                  <Label>How is your product funded?</Label>
                  <div>
                    <RadioLabel htmlFor="self-funded">
                      <Radio
                        type="radio"
                        id="self-funded"
                        name="productFunded"
                        value="Self-funded (bootstrapped)"
                        checked
                      />
                      Self-funded (bootstrapped)
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="in-process-of-fundraising">
                      <Radio
                        type="radio"
                        id="in-process-of-fundraising"
                        name="productFunded"
                        value="In process of fundraising"
                      />
                      In process of fundraising
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="secured-via-investors">
                      <Radio
                        type="radio"
                        id="secured-via-investors"
                        name="productFunded"
                        value="Funding already secured via investors"
                      />
                      Funding already secured via investors
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="not-sure">
                      <Radio
                        type="radio"
                        id="not-sure"
                        name="productFunded"
                        value="Not sure at the moment"
                      />
                      Not sure at the moment
                      <Mark />
                    </RadioLabel>
                  </div>
                  <ErrorMessage name="productFunded" component={TextError} />

                  <Label>What is your current available budget?*</Label>
                  <div>
                    <RadioLabel htmlFor="not-secured">
                      <Radio
                        type="radio"
                        id="not-secured"
                        name="budget"
                        value="Budget not secured yet"
                        checked
                      />
                      Budget not secured yet
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="under-30000">
                      <Radio
                        type="radio"
                        id="under-30000"
                        name="budget"
                        value="Under $30,000"
                      />
                      Under $30,000
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="between-30000-50000">
                      <Radio
                        type="radio"
                        id="between-30000-50000"
                        name="budget"
                        value="Between $30,000 and $50,000"
                      />
                      Between $30,000 and $50,000
                      <Mark />
                    </RadioLabel>
                  </div>
                  <div>
                    <RadioLabel htmlFor="over-50000">
                      <Radio
                        type="radio"
                        id="over-50000"
                        name="budget"
                        value="Over $50,000"
                      />
                      Over $50,000
                      <Mark />
                    </RadioLabel>
                  </div>
                  <ErrorMessage name="budget" component={TextError} />
                </Col>
              </M>
            </DoubleGrid>
            <M>
              <SubmitButton type="submit">SUBMIT</SubmitButton>
              {sending && <P>Sending message...</P>}
              {succes && (
                <P>
                  Thank you! Your message has been sent to us. We will contact
                  you as soon as possible.
                </P>
              )}
              {fail && (
                <P>
                  Ooops :( something went wrong. Please check your internet
                  connection or wait a few minutes and try again.
                </P>
              )}
              <div style={{ marginTop: "20px" }}>
                <Recaptcha
                  sitekey="6LehEngbAAAAAMVVKgjeeIgrn8tVmv2GUpqD0POv"
                  render="explicit"
                  onloadCallback={recaptchaLoaded}
                  verifyCallback={verifiedCallback}
                />
              </div>
            </M>
          </Form>
        </Container>
      </Formik>
    </Section>
  )
}

export default ContactForm
